import {AbsoluteCenter, IconButton} from '@chakra-ui/react';
import {AdvancedMarker, useAdvancedMarkerRef, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useRef, useState} from 'react';
import {InView} from 'react-intersection-observer';
import {useSelector} from 'react-redux';
import {useVuplex} from '../../../hooks/useVuplex';


const HeadingIcon = ({color, hideFrustum, size}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
    <defs>
      <linearGradient
        id="heading-lg"
        x1="40"
        x2="64"
        y1="8"
        y2="8"
        gradientTransform="rotate(-90 40 24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopOpacity="0.7"></stop>
        <stop offset="1" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="heading-lg-2"
        x1="1.26"
        x2="24.47"
        y1="0.4"
        y2="23.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopOpacity="0"></stop>
        <stop offset="0.86"></stop>
      </linearGradient>
      <linearGradient
        id="heading-lg-3"
        x1="-453.29"
        x2="-430.09"
        y1="0.4"
        y2="23.6"
        gradientTransform="matrix(-1 0 0 1 -406.55 0)"
        xlinkHref="#heading-lg-2"
      ></linearGradient>
    </defs>
    {!hideFrustum && (
      <>
        <path
          fill="url(#heading-lg)"
          d="M24 24L45 0 3 0 24 24z"
          data-name="Heading Gradient"
        ></path>
        <path
          fill="none"
          stroke="url(#heading-lg-2)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M23.36 24L2.36 0"
          data-name="Heading Edge L"
        ></path>
        <path
          fill="none"
          stroke="url(#heading-lg-3)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M24.64 24L45.64 0"
          data-name="Heading Edge R"
        ></path></>
    )}

    <g data-name="Local User Dot">
      <circle cx="24" cy="24" r="4" fill={color}></circle>
      <path
        d="M24 20c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4m0-2c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"
        opacity="0.2"
      ></path>
      <path
        fill="#fff"
        d="M24 21c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
      ></path>
    </g>


    {/*<defs>*/}
    {/*  <linearGradient*/}
    {/*    id="lin-grad-heading-frustum"*/}
    {/*    x1="40"*/}
    {/*    x2="64"*/}
    {/*    y1="8"*/}
    {/*    y2="8"*/}
    {/*    gradientTransform="rotate(-90 40 24)"*/}
    {/*    gradientUnits="userSpaceOnUse"*/}
    {/*  >*/}
    {/*    <stop offset="0" stopColor="#fff" stopOpacity="0.7"></stop>*/}
    {/*    <stop offset="1" stopColor={color} stopOpacity="0"></stop>*/}
    {/*  </linearGradient>*/}
    {/*</defs>*/}
    {/*<path fill="url(#lin-grad-heading-frustum)" d="M24 24L45 0 3 0 24 24z"></path>*/}
    {/*<circle cx="24" cy="24" r="4" fill={color}></circle>*/}
    {/*<path*/}
    {/*  d="M24 20c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4m0-2c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"*/}
    {/*  opacity="0.2"*/}
    {/*></path>*/}
    {/*<path*/}
    {/*  fill="#fff"*/}
    {/*  d="M24 21c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"*/}
    {/*></path>*/}
  </svg>
);


const MinimapHeadingMarker = ({isFixedNorth, onInViewChange}) => {
  const [refCallback, marker] = useAdvancedMarkerRef();
  const [icon, setIcon] = useState(null);
  const excluderCircleRef = useRef();

  const [userColor, setUserColor] = useState('#33bbff');

  const map = useMap();
  const maps = useMapsLibrary('maps');

  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const isPanoLoaded = panoId && panoLocation;

  useVuplex({
    mapNav: ({
      avatarLatitude,
      avatarLongitude,
      color,
      excluderDiameter,
      headingDegrees,
      menuHeadingDegrees,
      isTabletop,
      latitude,
      longitude,
    }) => {
      if (color !== userColor) setUserColor(color);

      if (map && map.center) {
        if (!excluderCircleRef.current) {
          excluderCircleRef.current = new maps.Circle({
            clickable: false,
            fillColor: '#33BBFF',
            map,
            strokeColor: '#33BBFF',
            strokeWeight: 2,
          });
        }

        excluderCircleRef.current.setOptions({
          center: {
            lat: latitude,
            lng: longitude,
          },
          radius: excluderDiameter / 2,
          strokeOpacity: isTabletop ? 0.8 : 0,
          fillOpacity: isTabletop ? 0.1 : 0,
        });
      }

      if (!marker) return;

      // console.log(marker.position);
      marker.position = isPanoLoaded
        ? {
          lat: panoLocation.latitude,
          lng: panoLocation.longitude,
        }
        : {
          lat: avatarLatitude,
          lng: avatarLongitude,
        };
      // window.headingMarker = marker;
      // window.headingMarkerIcon = icon;

      const style = _.get(icon, 'firstChild.style');
      if (!style) {
        console.warn('unexpected marker dom structure');
        return;
      }
      style.color = color;

      const rot = !isFixedNorth
        ? headingDegrees - menuHeadingDegrees
        : headingDegrees;
      style.transform = `rotate(${rot}deg)`;
    }
  }, [
    icon,
    isFixedNorth,
    isPanoLoaded,
    map,
    maps,
    marker,
    userColor,
  ]);

  return (
    <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
      // onClick={(e) => { }}
      ref={refCallback}
    >
      <InView onChange={onInViewChange}>
        <AbsoluteCenter>
          <IconButton
            bg="none"
            icon={<HeadingIcon color={userColor} size="4em"/>}
            isRound
            pointerEvents="none"
            ref={setIcon}
            // shadow="dark-lg"
          />
        </AbsoluteCenter>
      </InView>
    </AdvancedMarker>
  );
};

export default MinimapHeadingMarker;
