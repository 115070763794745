import {CloseIcon} from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Avatar,
  AvatarGroup, Badge,
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Skeleton,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {MdOutlinePanoramaPhotosphere} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../../../components/ButtonMinor';
import {useEnterKey} from '../../../../hooks/useEnterKey';
import useLocalStorageState from '../../../../hooks/useLocalStorageState';
import {useRetryOnAccessTokenRefresh} from '../../../../hooks/useRetryOnAccessTokenRefresh';
import {
  folderEditFormSaveRequest,
  folderEditFormSet,
  pinSaveRequest, pinsBalanceRequest,
  pinsPanoIdSearchRequest
} from '../../../../reducers/pinsReducer';
import {sendAudioClick, sendPanoIdSearch} from '../../../../vuplex';
import PinEditFoldersList from '../../PinEditFoldersList';
import PinFolderCreateForm from '../../PinFolderCreateForm';
import UpgradeButton from '../../UpgradeButton';
import MinimapPanoMarker from '../MinimapPanoMarker';
import {PanoUnloadButton} from './PanoUnloadButton';
import axios from 'axios';


const ToastContent = ({onClose}) => {
  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const suggestedLabel = useSelector(s => s.inApp.panoSuggestedLabel);
  const userId = useSelector(s => s.inApp.userId);
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(true);
  const [showSaveForm, setShowSaveForm] = useState(false);

  const {
    error,
    isLoading,
    response: pinsSearchResponse,
  } = useSelector(s => s.pins.pinsPanoIdSearch);

  const {hasNextPage, page, pins = []} = pinsSearchResponse || {};

  const handleClickExistingPins = (e) => {
    sendAudioClick(e);
    sendPanoIdSearch({
      lat: panoLocation.latitude,
      lon: panoLocation.longitude,
      panoId,
    });
  };

  const isOwnedByUser = pins.find(p => p.isOwnedByUser);
  const pinsMax = 5;
  const {_id: worldId, rules} = useSelector(s => s.inApp.world) || {};
  const {isPrivate: isPrivateWorld, isSolo: isSoloWorld} = rules || {};

  const [pinLabel, setPinLabel] = useState(suggestedLabel);
  const [recentFolderId, setRecentFolderId] = useLocalStorageState('pinSaveRecentFolder', null);
  const [selectedFolderId, setSelectedFolderId] = useState(recentFolderId);
  const folderEditForm = useSelector(s => s.pins.folderEditForm);
  const showFolderEditForm = !_.isEmpty(folderEditForm);
  const isFolderFormValid = showFolderEditForm
    ? !!folderEditForm.label
    : !!selectedFolderId;


  useEffect(() => {
    setPinLabel(suggestedLabel);
  }, [suggestedLabel]);

  const toast = useToast();

  const savePlace = async (folderId) => {
    setRecentFolderId(folderId);

    setShowDialog(false);

    const {latitude, longitude} = panoLocation;
    const {data} = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`, {
      headers: {
        'User-Agent': `${userId}/1.0`,
      },
    })
      .catch((e) => {
        console.error(e);
        return {};
      });

    const {display_name} = data || {};

    const {error, payload} = await dispatch(pinSaveRequest({
      address: display_name || 'unknown address',
      label: pinLabel,
      folderId,
      panoId,
      panoLocation,
      suggestedLabel,
    }));


    if (error) {
      toast({
        status: 'error',
        description: 'Unable to save place. Please try again.',
      });
    } else {
      const {isInPrivateFolder, isInSharedFolder} = payload;
      const showInvisibleWarning = isPrivateWorld
        ? !isSoloWorld && isInPrivateFolder && !isInSharedFolder
        : isInPrivateFolder;

      toast({
        status: 'success',
        description: `Place saved${showInvisibleWarning ? ' (not visible to others in this world)' : ''}`,
      });

      // if (showBalance) {
      //   toast({
      //     status: 'info',
      //     description: pinsBalance === 1
      //       ? `Upgrade to save more places`
      //       : `You may save ${pinsBalance - 1} more ${pinsBalance === 2 ? 'place' : 'places'}`,
      //   });
      // }
    }
  };

  const handleSaveClick = async () => {
    if (showFolderEditForm) {
      const {error, payload} = await dispatch(folderEditFormSaveRequest());
      if (error) {
        toast({
          status: 'error',
          description: 'Unable to create Place List. Please try again or pick an existing list.',
        });
      } else {
        await savePlace(payload._id);
      }
    } else {
      await savePlace(selectedFolderId);
    }
  };

  useRetryOnAccessTokenRefresh(() => dispatch(pinsBalanceRequest({})));
  const {pinsBalance} = useSelector(s => s.pins.pinsBalanceRequest.response) || {};
  const hasBalance = pinsBalance > 0;
  const showBalance = hasBalance && pinsBalance <= 10;
  console.log(pinsBalance)

  const disabledReason = !hasBalance
    ? 'Upgrade to save more places'
    : !pinLabel
      ? 'Please enter a description'
      : !isFolderFormValid
        ? showFolderEditForm
          ? 'Please name your new list'
          : 'Please select a list'
        : null;

  useEnterKey(async () => {
    if (!disabledReason && !showFolderEditForm)
      await handleSaveClick();
  });

  // console.log('pano', showDialog, showSaveForm)



  return (
    <Flex
      alignItems="start"
      gap={3}
      w="full"
    >
      <PanoUnloadButton/>

      {showDialog && (
        <Stack>
          {showSaveForm && (
            <Card>
              <CardBody>
                <Flex
                  p={2}
                  gap={2}
                >
                  <FormControl isRequired>
                    <FormLabel>Description</FormLabel>
                    <Input
                      onChange={(evt) => setPinLabel(evt.target.value)}
                      value={pinLabel}
                    />
                    <FormHelperText>Add a description</FormHelperText>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>List</FormLabel>
                    {showFolderEditForm
                      ? <PinFolderCreateForm/>
                      : (
                        <PinEditFoldersList
                          onFolderClick={(f) => {
                            if (f) setSelectedFolderId(f._id);
                            else {
                              dispatch(folderEditFormSet({
                                label: '',
                                isSolo: false,
                                sharedInWorld: '',
                              }));
                            }
                          }}
                          selectedFolderId={selectedFolderId}
                        />)}
                    <FormHelperText>Choose a or create a Place List</FormHelperText>
                  </FormControl>
                </Flex>
                <Center gap={2}>
                  <ButtonMinor
                    shadow="md"
                    onClick={() => {
                      setShowDialog(false);
                    }}
                  >
                    Cancel
                  </ButtonMinor>

                  <ButtonMinor
                    disabledReason={disabledReason}
                    shadow="md"
                    onClick={handleSaveClick}
                  >
                    Save to Places

                    {showBalance && (
                      <Badge
                        bg="wooorldBlue._"
                        borderRadius="3xl"
                        bottom="-0.5em"
                        color="white"
                        paddingX={2}
                        position="absolute"
                        right={0}
                      >
                        {pinsBalance}
                      </Badge>
                    )}
                  </ButtonMinor>

                  {!hasBalance && (
                    <AbsoluteCenter w="10em">
                      <UpgradeButton
                        descriptor="PinEditForm"
                        top="1.8em"
                        right={0}
                        packageName="explorer+"
                        position="absolute"
                        transform="scale(0.8)"
                        zIndex="sticky"
                      />
                    </AbsoluteCenter>
                  )}
                </Center>
              </CardBody>

            </Card>

          )}

          {!showSaveForm && (
            <Flex
              alignItems="center"
              bg="#007AFF"
              borderRadius="2xl"
              color="white"
              gap={2}
              p={2}
            >
              <Skeleton isLoaded={!isLoading}>
                <Box>
                  {!pins.length
                    ? <Text px={2}>You found a new 360!</Text>
                    : (
                      <Flex
                        alignItems="center"
                        bg="whiteAlpha.400"
                        borderRadius="full"
                        p={0}
                        paddingLeft={2}
                      >
                        <Text>Saved by:</Text>
                        <AvatarGroup
                          onClick={handleClickExistingPins}
                          marginX={2}
                        >
                          {_.take(pins, pinsMax).map(({
                            _id,
                            ownerActivityStatus,
                            ownerAlias,
                            ownerProfilePhotoURL,
                          }, i) => (
                            <Avatar
                              borderColor={ownerActivityStatus === 'online' ? 'lightgreen' : 'null'}
                              key={i}
                              name={ownerAlias}
                              shadow={`2px 1px 4px #000000aa`}
                              showBorder
                              size="xs"
                              src={ownerProfilePhotoURL}
                            />
                          ))}
                        </AvatarGroup>

                        {pins.length > pinsMax && (<Text marginRight={1}>+{pins.length - pinsMax}</Text>)}

                        <ButtonMinor onClick={handleClickExistingPins}>
                          See All
                        </ButtonMinor>
                      </Flex>

                    )}

                </Box>
              </Skeleton>

              <ButtonMinor
                disabledReason={isOwnedByUser ? 'You have already saved this 360.' : null}
                onClick={() => {
                  setShowSaveForm(true);
                }}
              >
                <MdOutlinePanoramaPhotosphere/>
                &nbsp;
                Save 360
              </ButtonMinor>

              <ButtonMinor
                bg="none"
                borderLeft="solid 1px"
                borderColor="whiteAlpha.500"
                borderRadius={0}
                onClick={() => setShowDialog(false)}
              >
                <CloseIcon color="white"/>
              </ButtonMinor>
            </Flex>
          )}

        </Stack>
      )}
    </Flex>


  );
};

const MinimapPanoToast = ({}) => {
  const dispatch = useDispatch();

  // const isEditingPin = pathname.endsWith('pins/edit');
  // const isList = pathname.endsWith('pins/pins');


  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const isPanoLoaded = panoId && panoLocation;

  const [reopenToast, setReopenToast] = useState(0);

  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    const closeCurrentToast = () => toast.close(toastIdRef.current);

    if (panoId) {
      toast.closeAll();
      toastIdRef.current = toast({
        render: () => <ToastContent onClose={closeCurrentToast}/>,
        duration: null,
        icon: <></>,
        isClosable: false,
        position: 'bottom',
      });
    } else {
      closeCurrentToast();
    }

    return closeCurrentToast;
  }, [panoId, reopenToast]);

  useEffect(() => {
    if (panoId) {
      dispatch(pinsPanoIdSearchRequest({
        isNew: true,
        panoId,
      }));
    }
  }, [panoId]);

  return isPanoLoaded && <MinimapPanoMarker onClick={() => setReopenToast(reopenToast + 1)}/>;
};

export default MinimapPanoToast;
